import type { Path, PathObject, Position } from "../types/Model";
import _ from "lodash";
import { Node } from "../types/Graph";

export const pathListToObject = (pathList: Path[]): PathObject =>
  pathList.reduce((acc, p: Path) => _.set(acc, p, {}), {});

export const pathObjectToList = (pathObject: PathObject): Path[] =>
  Object.keys(pathObject).flatMap((k) =>
    pathObjectToList(pathObject[k]).map((p) => [k].concat(p))
  );

export const random2DPosition = (
  xlimits = [-0.5, 0.5],
  ylimits = [-0.5, 0.5]
): Position => ({
  xpos: (xlimits[1] - xlimits[0]) * Math.random() + xlimits[0],
  ypos: (ylimits[1] - ylimits[0]) * Math.random() + ylimits[0],
});

export const rotate = (pos: Position, ang: number) => ({
  xpos: Math.cos(ang) * pos.xpos - Math.sin(ang) * pos.ypos,
  ypos: Math.sin(ang) * pos.xpos + Math.cos(ang) * pos.ypos,
});

export const translate = (pos: Position, translationPos: Position) => ({
  xpos: pos.xpos + translationPos.xpos,
  ypos: pos.ypos + translationPos.ypos,
});

export const getNodeWithName = (nodes: Node[], name: string) =>
  nodes.find((n) => n.name === name) ?? {
    name: "ERROR",
    type: "Entity",
    position: random2DPosition(),
  };

// const getEdgeFreqs = (node: Node, edges: Edge[]) =>
//   edges.reduce(
//     (acc, e) => ({
//       in: acc.in + (+ (e.to === node.name)), //TIL unary + operator
//       out: acc.out + (+ (e.from === node.name))
//     }),
//     {in: 0, out: 0}
//   )

export const findNodeWithName = (name: String, nodes: Node[]): Node => {
    const nodeOrUndefined: Node | undefined = nodes.find(
      (n: Node) => n.name === name
    );
    if (nodeOrUndefined === undefined) {
      throw `Node with name: ${name} not found`;
    } else {
      return nodeOrUndefined;
    }
  };

export const convertRelToRealPosition = (
  { xpos, ypos }: Position,
  width: number,
  height: number
) => ({
  xpos: xpos * width + width / 2,
  ypos: ypos * height + height / 2,
});

export const convertRealToRelPosition = (
  { xpos, ypos }: Position,
  width: number,
  height: number
) => ({
  xpos: (xpos - width / 2) / width,
  ypos: (ypos - height / 2) / height,
});

export const realPosOfNodeWithName = (
  name: String,
  nodes: Node[],
  width: number,
  height: number
) =>
  convertRelToRealPosition(findNodeWithName(name, nodes).position, width, height);    
import modelReducer from "../../reducers/modelReducer";
import ButtonPanel, { ButtonDefinition } from "../buttonPanel";
import { MenuProps } from "./menuTypes";
import { Node, NodeType } from "../../types/Graph";
import { useEffect } from "react";
import { dedupeNodes } from "../../util/ModelUtil";

export const EntityTransformsNodes: Node[] = [
  {
    name: "instanceOf",
    type: NodeType.Relation,
    position: { xpos: 0, ypos: 0 },
  },
];

const EntityTransformsMenu = ({ model, setModel, selection }: MenuProps) => {
  useEffect(() => {
    console.log("adding entity transform nodes..");
    const newModelNodes = dedupeNodes(
      model.nodes.concat(EntityTransformsNodes)
    );
    const modelWithLibraryNodes = Object.assign({}, model, {
      nodes: newModelNodes,
    });
    console.log(JSON.stringify(modelWithLibraryNodes, null, 2));
    setModel(modelWithLibraryNodes);
  }, []);
  const addTypeCallback = () => {
    const name = prompt("Name?") ?? "";
    const newModel = modelReducer(model, { type: "ADD_TYPE", data: name });
    setModel(newModel);
  };
  const addEntityCallback = () => {
    const instanceOf = prompt("instance of?") ?? "";
    const name = prompt("Name?") ?? "";
    const newModel = modelReducer(model, {
      type: "ADD_ENTITY",
      data: { name, instanceOf, protected: true },
    });
    setModel(newModel);
  };
  const addRelationCallback = () => {
    const name = prompt("Name?") ?? "";
    const newModel = modelReducer(model, { type: "ADD_RELATION", data: name });
    setModel(newModel);
  };
  const addPathCallback = () => {
    const path = prompt("Path?")?.split(".") ?? [];
    if (path.length === 0) {
      return;
    }
    const newModel = modelReducer(model, { type: "ADD_PATH", data: path });
    setModel(newModel);
  };
  const clearModelCallback = () => {
    setModel(modelReducer(model, { type: "CLEAR_MODEL" }));
  };
  const entityMenuButtons: ButtonDefinition[] = [
    { label: "add type", callback: addTypeCallback },
    { label: "add entity", callback: addEntityCallback },
    { label: "add relation", callback: addRelationCallback },
    { label: "add path", callback: addPathCallback },
    { label: "clear model", callback: clearModelCallback },
  ];

  const description = "This is a description";
  return (
    <ButtonPanel
      buttons={entityMenuButtons}
      title="Entity Transform Modeling Abstractions"
      description={description}
    />
  );
};

export default EntityTransformsMenu;

import { random2DPosition } from "../../util/GraphUtil";
import { applyHierarchicalLayout } from "../../util/LayoutUtil";
import ButtonPanel, { ButtonDefinition } from "../buttonPanel";
import { MenuProps } from "./menuTypes";
import type { Node } from "../../types/Graph";
import * as d3 from 'd3'
import { pathsToEdges } from "../../util/ModelUtil";

const avg = (a: number[]) => a.reduce((acc, n) => acc + n) / a.length;

// TODO: replace hard coded force layout parameters
const nodeAttractionFactor = 0.04;
const edgeSpringFactor = 0.3;

const GraphLayoutTransformsMenu = ({
  model,
  setModel,
  selection,
}: MenuProps) => {
  const randomLayoutCallback = () => {
    const newNodes: Node[] = model.nodes.map((n: Node) =>
      Object.assign({}, n, {
        position: random2DPosition([-1 / 2, 1 / 2], [-1 / 2, 1 / 2]),
      })
    );
    setModel(Object.assign({}, model, { nodes: newNodes }));
  };
  const hierarchicalLayoutCallback = () => {
    setModel(applyHierarchicalLayout(model));
  };
  const forceLayoutCallback = () => {
    const edges = pathsToEdges(model.paths)
    const nodes = model.nodes.map(n => ({
      x: n.position.xpos,
      y: n.position.ypos,
      name: n.name
    }))
    
    const ticked = () => setModel(
      Object.assign(
        {},
        model,  
        { nodes: nodes.map(n => ({ position: { xpos: n.x, ypos: n.y }, name: n.name }))}
      )
    );

    // // Let's list the force we wanna apply on the network
    const simulation = d3.forceSimulation(nodes)                 // Force algorithm is applied to data.nodes
      .force("link", d3.forceLink(edges).id(n => nodes[n.index ?? 0].name))
      .force("charge", d3.forceManyBody().strength(-20))         // This adds repulsion between nodes. Play with the -400 for the repulsion strength
      .force("center", d3.forceCenter(0, 0))     // This force attracts nodes to the center of the svg area
      .on("end", ticked);
  };
  const centerCallback = () => {
    const centerXOffset = avg(model.nodes.map((n: Node) => n.position.xpos));
    const centerYOffset = avg(model.nodes.map((n: Node) => n.position.ypos));
    const newNodes = model.nodes.map((n: Node) =>
      Object.assign({}, n, {
        position: {
          xpos: n.position.xpos - centerXOffset,
          ypos: n.position.ypos - centerYOffset,
        },
      })
    );
    const newModel = Object.assign({}, model, { nodes: newNodes });
    setModel(newModel);
  };
  const zoomToFitCallback = () => {
    const absXs = model.nodes.map((n: Node) => Math.abs(n.position.xpos));
    const xMaxAbs = Math.max(...absXs, 0.1);
    const xZoom = (0.8 * 0.5) / xMaxAbs;

    const absYs = model.nodes.map((n: Node) => Math.abs(n.position.ypos));
    const yMaxAbs = Math.max(...absYs, 0.1);
    const yZoom = (0.8 * 0.5) / yMaxAbs;

    const newNodes = model.nodes.map((n: Node) =>
      Object.assign({}, n, {
        position: {
          xpos: n.position.xpos * xZoom,
          ypos: n.position.ypos * yZoom,
        },
      })
    );
    const newModel = Object.assign({}, model, { nodes: newNodes });
    setModel(newModel);
  };

  const menuButtons: ButtonDefinition[] = [
    // { label: 'hierarchical layout', callback: hierarchicalLayoutCallback },
    { label: 'force layout', callback: forceLayoutCallback },
    { label: "random layout", callback: randomLayoutCallback },
    { label: "center", callback: centerCallback },
    { label: "zoom to fit", callback: zoomToFitCallback },
  ];
  const description = "";
  return (
    <ButtonPanel
      buttons={menuButtons}
      title="Graph Layout Transforms"
      description={description}
    />
  );
};

export default GraphLayoutTransformsMenu;

import { Model } from "../types/Model";
import { dedupeNodes, nodeToInstancePath } from "../util/ModelUtil";
import { random2DPosition } from "../util/GraphUtil";
import { NodeType } from "../types/Graph";

export type ModelActionType =
  | "ADD_TYPE"
  | "ADD_ENTITY"
  | "ADD_RELATION"
  | "REMOVE_NODES"
  | "CLEAR_MODEL"
  | "ADD_PATH"
  | "REMOVE_PATH";

export type ModelAction = {
  type: ModelActionType;
  data?: any;
};

const modelReducer = (state: Model, action: ModelAction) => {
  switch (action.type) {
    case "ADD_ENTITY":
      return action.data
        ? Object.assign({}, state, {
            nodes: dedupeNodes(
              state.nodes.concat([
                {
                  name: action.data.name,
                  position: random2DPosition(),
                  type: NodeType.Entity,
                  instanceOf: action.data.instanceOf,
                },
              ])
            ),
            paths: [...state.paths, nodeToInstancePath(action.data)],
          })
        : state;
    case "ADD_RELATION":
      return action.data
        ? Object.assign({}, state, {
            nodes: dedupeNodes(
              state.nodes.concat([
                {
                  name: action.data,
                  position: random2DPosition(),
                  type: NodeType.Relation,
                },
              ])
            ),
          })
        : state;
    case "REMOVE_NODES":
      return Object.assign({}, state, {
        nodes: state.nodes.filter((n) => !action.data.includes(n.name)),
        paths: state.paths.filter((p) =>
          p.every((s) => !action.data.includes(s))
        ),
      });
    case "ADD_PATH":
      // TODO: check all nodes exist?
      return Object.assign({}, state, { paths: [...state.paths, action.data] });
    case "REMOVE_PATH":
      return Object.assign({}, state, {
        paths: state.paths.filter((p) =>
          p.every((s, i) => s !== action.data[i])
        ),
      });
    case "CLEAR_MODEL":
      return Object.assign({}, state, { nodes: [], paths: [] });
    default:
      return state;
  }
};

export default modelReducer;

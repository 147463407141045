import React from "react";
import Panel from "./panel";

export type ButtonDefinition = {
  label: string;
  callback: () => void;
};

const ButtonPanel = ({
  buttons,
  title,
  description,
}: {
  title?: string;
  buttons: ButtonDefinition[];
  description: string;
}) => (
  <Panel title={title ?? ""}>
    <ButtonList buttons={buttons} />
  </Panel>
);

export const ButtonList = ({ buttons }: { buttons: ButtonDefinition[] }) => (
  <span id="button-list">
    {buttons.map((b, i) => (
      <span key={i}>
        <button
          className="bg-slate-400 float-right w-full border-solid border-black border-1 m-1 p-1 text-white"
          onClick={b.callback}
        >
          {b.label}
        </button>
        <br />
      </span>
    ))}
  </span>
);

export default ButtonPanel;

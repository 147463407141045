import React from "react";

const Panel = ({
  children,
  title,
  description,
}: {
  title: string;
  children?: React.ReactChild | React.ReactChild[];
  description?: string;
}) => {
  return (
    <div className="w-auto border-solid border-1 border-black">
      <h2 className="text-xl font-bold">{title}</h2>
      <p>{description}</p>
      {children}
    </div>
  );
};

export default Panel;

import { Graph, Node, Edge } from "../types/Graph";
import { Model, Path } from "../types/Model";
import _ from "lodash";

export const modelToGraph = (model: Model): Graph => ({
  name: model.name,
  nodes: model.nodes,
  edges: pathsToEdges(model.paths),
});

export const nodeToInstancePath = (node: Node) => [
  node.name,
  "instanceOf",
  node.instanceOf ?? "",
];

const pathToEdge = (path: Path): Edge[] =>
  path.reduce(
    (edges, seg, ind) =>
      ind === 0
        ? edges
        : [...edges, { source: path[ind - 1], target: seg, path }],
    [] as Edge[]
  );

export const pathsToEdges = (paths: Path[]): Edge[] =>
  paths.flatMap((p) => pathToEdge(p));

export const pathsEquality = (l1: Path[], l2: Path[]) => _.isEqual(l1, l2);

const queryPath = (query: Path, path: Path) =>
  query.reduce((acc, seg, ind: number) => {
    return acc && (seg === "*" || seg === path[ind]);
  }, true);

export const queryPaths = (query: Path, paths: Path[]) =>
  paths.filter((p) => queryPath(query, p));

export const dedupeNodes = (nodes: Node[]) =>
  _.uniqWith(nodes, (n1: Node, n2: Node) => n1.name === n2.name);

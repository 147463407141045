import GraphLayoutTransformsMenu from "./components/menus/graphLayoutTransformsMenu";
import EntityTransformsMenu from "./components/menus/entityTransformsMenu";
import SelectionMenu from "./components/menus/selectionMenu";
import ValidationsMenu from "./components/menus/validationsMenu";
import RemoteServerMenu from "./components/menus/remoteServerMenu";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Node } from "./types/Graph";
import { Model } from "./types/Model";
import { useEffect, useState } from "react";
import Panel from "./components/panel";
import { Toaster } from "react-hot-toast";
import { modelToGraph } from "./util/ModelUtil";
import GraphD3 from "./components/graphD3";

const initialModel: Model = {
  nodes: [],
  paths: [],
};

const libraryComponents = [
  EntityTransformsMenu,
  GraphLayoutTransformsMenu,
  SelectionMenu,
  // ValidationsMenu,
  RemoteServerMenu
];

function App() {
  const [model, setModel] = useState<Model>(initialModel);
  const [selection, setSelection] = useState<string[]>([]);
  const [searchParams] = useSearchParams();
  const [menusOpen, setMenusOpen] = useState(false);
  const toggleMenusOpen = () => setMenusOpen(!menusOpen);
  const history = useNavigate();
  const updateModel = (m: Model) => {
    setModel(m);
    const mstr = JSON.stringify(m);
    const newURLPath = `/?model=${mstr}`;
    history(newURLPath);
  };
  const setModelNodes = (nodes: Node[]) => {
    const newModel = Object.assign({}, model, { nodes })
    const mstr = JSON.stringify(newModel)
    setModel(Object.assign({}, model, { nodes }));
    history(`/?model=${mstr}`)
  };
  useEffect(() => {
    if (searchParams.has("model")) {
      const queryParamModel =
        JSON.parse(searchParams.get("model") || "") || initialModel;
      setModel(queryParamModel);
    } else {
      setModel(initialModel);
    }
  }, [searchParams, setModel]);

  return model ? (
    <div className="App">
      <main>
        <div className="w-screen h-screen absolute">
          <GraphD3
            graph={modelToGraph(model)}
            paths={model.paths}
            selection={selection}
            setSelection={setSelection}
            setModelNodes={setModelNodes}
          />
        </div>
        <div
          id="menus"
          className="max-h-full overflow-y-scroll absolute z-10 opacity-50 bg-white p-1 pr-2 right-0"
        >
          <button className="float-right p-2" onClick={toggleMenusOpen}>
            &#9776;
          </button>
          <div id="children" className={menusOpen ? "open" : ""}>
            {libraryComponents.map((C, i) => (
              <C
                model={model}
                setModel={updateModel}
                selection={selection}
                setSelection={setSelection}
                key={i}
              />
            ))}
            {/* <ModelPanel model={model} /> */}
          </div>
        </div>
        "
      </main>
      <Toaster />
    </div>
  ) : (
    <div>Loading model...</div>
  );
}

const ModelPanel = ({ model }: { model: Model }) => (
  <Panel title="Model">
    {model.name ? <h3>Name: {model.name}</h3> : ""}
    <h3>Nodes:</h3>
    <ul>
      {model.nodes.map((n) => (
        <li key={n.name}>
          {n.name} @ x:{n.position.xpos}, y: {n.position.ypos}
        </li>
      ))}
    </ul>
    <h3>Paths:</h3>
    <ul>
      {model.paths.map((p) => (
        <li key={p.join(",")}>{JSON.stringify(p, null, 2)}</li>
      ))}
    </ul>
  </Panel>
);
export default App;

import type { Path, Position } from "./Model";

export type Node = {
  name: string;
  position: Position;
  type: NodeType;
  instanceOf?: string;
  group?: string;
};

export type Group = Node[]

export type Edge = {
  source: string;
  target: string;
  path: Path;
};

export type Graph = {
  name?: string;
  nodes: Node[];
  edges: Edge[];
};

export type ShortNode = [string, Path[]];

export enum NodeType {
  Entity = "ENTITY",
  Relation = "RELATION",
}

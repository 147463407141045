import { convertRealToRelPosition, realPosOfNodeWithName } from "../util/GraphUtil"
import { Graph, Edge, Node, Group } from "../types/Graph";
import * as d3 from "d3";
import { drag } from "d3-drag";
import { Position } from "../types/Model";
import { useRef, useState, useEffect } from "react";

const margin = { top: 10, right: 30, bottom: 30, left: 40 };
const width = window.innerWidth - margin.left - margin.right;
const height = window.innerHeight - margin.top - margin.bottom;

const GraphD3 = ({
  graph,
  paths,
  selection,
  setSelection,
  setModelNodes,
}: {
  graph: Graph;
  selection: string[];
  paths: string[][];
  setSelection: (selection: string[]) => void;
  setModelNodes: (nodes: Node[]) => void;
}) => {
  const dragHandler = drag();
  const containerRef = useRef(null);
  const [tempGraph, setTempGraph] = useState<Graph>(graph);
  const { nodes, edges } = tempGraph;

  useEffect(() => {
    console.log("useEffect to setTempGraph");
    setTempGraph(graph);
  }, [graph]);

  const updateNodePosition = (
    name: String,
    position: Position,
    graph: Graph
  ) => {
    const newNodes = tempGraph.nodes.map((n) =>
      n.name == name ? Object.assign({}, n, { position }) : n
    );
    // console.log("newNodes: " + JSON.stringify(newNodes, null, 2));
    const newGraph = Object.assign({}, tempGraph, { nodes: newNodes });
    return newGraph;
  };

  const svg = d3.select<SVGGElement, unknown>("#graph-d3 > g")

  const svgNode = document.getElementById("graph-d3");

  if (svgNode) {
    const groupNode = (svgNode && svgNode.children[0]) ?? "";

    while (groupNode.children[0]) {
      groupNode.removeChild(groupNode.children[0]);
    }
  }

  const brush = d3.brush()
  svg
    .call(brush)
    .on('start', (evt) => {
      console.log('start')
    })
    .on('end', (evt) => {
      console.log('end')
    })

  // svg.selectAll('g').call(
  //   d3
  //     .drag()
  //     .on("start", (event, d) => {
  //       return true
  //     })
  // )

  // arrowheads for edges
  svg.append("svg:defs").append("svg:marker")
    .attr("id", "triangle")
    .attr("refX", 20)
    .attr("refY", 6)
    .attr("markerWidth", 30)
    .attr("markerHeight", 30)
    .attr("orient", "auto")
    .append("path")
    .attr("d", "M 0 0 12 6 0 12 3 6")
    .style("fill", "white")

  const relevantPaths = paths.filter(p => selection.includes(p[0]))
  const selectedEdges = edges.filter(e => paths.some(p => p.includes(e.source) && p.includes(e.target)))
  const edgeD3 = svg
    .selectAll("line")
    .data<Edge>(edges)
    .join("line")
    .style("stroke", (l) => (paths
      .filter(p => selection.includes(p[0])) ? "#AAA" : "#999"))
    .style("stroke-width", (l) => (paths.filter(
      p => selection.includes(p[0]) && p.includes(l.source) && p.includes(l.target)
    ).length>0 ? 2 : 1))
    .attr("marker-end", "url(#triangle)")

  const nodeRadius = 10
  const nodeD3 = svg
    .selectAll("g")
    .data<Node>(nodes)
    .join("g")
    .attr(
      "transform",
      (n) => `translate(${n.position.xpos}, ${n.position.ypos})`
    )
    .append<SVGCircleElement>("circle")
    .attr("r", nodeRadius)
    .attr("name", (n) => n.name)
    .style("stroke", (n) => (selection.includes(n.name) ? "#FFF" : "#AAA"))
    .style("stroke-width", (n) => (selection.includes(n.name) ? 3 : 1))
    .style("fill", "black")
    .call(
      d3
        .drag<SVGCircleElement, Node>()
        .on("start", (event, d) => {
          const isSelected = selection.includes(d.name)
          setSelection(
            isSelected ?
              selection.filter(n => n && (n!=d.name)) :
              [...selection, d.name]
          );
        })
        .on("drag", (event, d) => {
          // console.log(`drag event x:${event.x}, y: ${event.y}`);
          const normPosition = convertRealToRelPosition({
            xpos: event.x,
            ypos: event.y,
          }, width, height);
          const newGraph = updateNodePosition(d.name, normPosition, tempGraph);
          setTempGraph(newGraph);
        })
        .on("end", (event, d) => {
          console.log(
            "position: " + JSON.stringify({ xpos: event.x, ypos: event.y })
          );
          const normPosition = convertRealToRelPosition({
            xpos: event.x,
            ypos: event.y,
          }, width, height);
          console.log("rel position: " + JSON.stringify(normPosition));
          // console.log(
          //   "TempGraph.nodes:" + JSON.stringify(tempGraph.nodes, null, 2)
          // );
          const newGraph = updateNodePosition(d.name, normPosition, tempGraph);
          setModelNodes(newGraph.nodes);
        })
    )
    // .append<SVGCircleElement>('circle')
    // .attr('r', nodeRadius/2)

  const nodeTextD3 = svg
    .selectAll("text")
    .data(nodes)
    .join("text")
    .attr("fill", "#FFF")
    .text((n) => n.name);

  if (selection.length > 0){
    const selectionNodePositions = nodes.filter(n => n.name in selection)
      .map(n => n.position)
    const max_x = Math.max(...selectionNodePositions.map(p => p.xpos))
    const min_x = Math.min(...selectionNodePositions.map(p => p.xpos))
    const max_y = Math.max(...selectionNodePositions.map(p => p.ypos))
    const min_y = Math.min(...selectionNodePositions.map(p => p.ypos))
  
    const dragBox = svg.select('rect')
      .append('rect')
      .attr('x', min_x)
      .attr('y', min_y)
      .attr('width', max_x - min_x)
      .attr('height', max_y - min_y)
      .attr('fill', '#888')
  }

  const ticked = () => {
    edgeD3
      .attr("x1", (d: Edge) => realPosOfNodeWithName(d.source, nodes, width, height).xpos)
      .attr("y1", (d: Edge) => realPosOfNodeWithName(d.source, nodes, width, height).ypos)
      .attr("x2", (d: Edge) => realPosOfNodeWithName(d.target, nodes, width, height).xpos)
      .attr("y2", (d: Edge) => realPosOfNodeWithName(d.target, nodes, width, height).ypos);

    nodeD3
      .attr("cx", (d) => d.position.xpos * width + width / 2)
      .attr("cy", (d) => d.position.ypos * height + height / 2);

    nodeTextD3
      .attr("x", (d) => d.position.xpos * width + width / 2 + 10)
      .attr("y", (d) => d.position.ypos * height + height / 2 - 5);
  };

  ticked()

  return (
    <svg
      id="graph-d3"
      className="bg-black"
      style={{ width: window.innerWidth, height: window.innerHeight }}
    >
      {/* <g transform={`translate(${margin.left}, ${margin.top})`} /> */}
      <g />
    </svg>
  );
};

export default GraphD3;

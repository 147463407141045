import modelReducer from "../../reducers/modelReducer";
import { Node, NodeType } from "../../types/Graph";
import { Path } from "../../types/Model";
import { pathListToObject } from "../../util/GraphUtil";
import { queryPaths } from "../../util/ModelUtil";
import { ButtonDefinition, ButtonList } from "../buttonPanel";
import Panel from "../panel";
import { MenuProps } from "./menuTypes";

const SelectionMenu = ({
  model,
  setModel,
  selection,
  setSelection,
}: MenuProps) => {
  const setSelectionCallback = () => {
    const name = prompt("Name?") ?? "";
    setSelection([name]);
  };
  const clearSelectionCallback = () => {
    setSelection([]);
  }
  const removeSelectionCallback = () => {
    const newModel = modelReducer(model, {
      type: "REMOVE_NODES",
      data: selection,
    });
    setSelection([])
    setModel(newModel);
  };
  const buttons: ButtonDefinition[] = [
    { label: "set selection", callback: setSelectionCallback },
    { label: "clear selection", callback: clearSelectionCallback },
    { label: "remove selection", callback: removeSelectionCallback },
  ];
  const currentSelectionPaths = selection.reduce(
    (acc, s) => acc.concat(queryPaths([s, "*", "*"], model.paths)),
    [] as Path[]
  );
  const currentSelectionObject = pathListToObject(currentSelectionPaths);
  const currentSelectionNodes: Node[] = selection.map(
    (s) =>
      model.nodes.find((n) => n.name === s) ?? {
        name: `${s} not found`,
        position: { xpos: 0, ypos: 0 },
        type: NodeType.Entity,
      }
  );
  const currentSelection = (
    <span>
      {currentSelectionNodes.map(n => `${n.name}- ${n.type}`).join(', ')}
      <div>paths: {JSON.stringify(currentSelectionPaths, null, 2)}</div>
      <div>{JSON.stringify(currentSelectionObject, null, 2)}</div>
    </span>
  );
  return (
    <Panel title="Selection">
      <ButtonList buttons={buttons} />
      <br />
      {currentSelection}
    </Panel>
  );
};

export default SelectionMenu;

import { ButtonDefinition, ButtonList } from "../buttonPanel";
import Panel from "../panel";
import { MenuProps } from "./menuTypes";

const url_base = "http://localhost:8000"

const RemoteServerMenu = ({
  model,
  setModel,
}: MenuProps) => {
  const aiChatCallback = async () => {
    const message = prompt("Chat message:")
    const response = await fetch(`${url_base}/msgassistant`, {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ model: JSON.stringify(model), message })
    })
    const jsonResponse = await response.json()
    const newModel = JSON.parse(jsonResponse.message)
    setModel(newModel);
  };
  const startThreadCallback = async () => {
    const response = await fetch(`${url_base}/thread`, {
      method: "POST",
      mode: "cors",
      credentials: "same-origin"
    })
    return response
  }
  const buttons: ButtonDefinition[] = [
    { label: "AI Chat", callback: aiChatCallback },
    { label: "Start Thread", callback: startThreadCallback },
  ];
  return (
    <Panel title="RemoteServer">
      <ButtonList buttons={buttons} />
    </Panel>
  );
};

export default RemoteServerMenu;
